<template>
  <div>
    <p class="text-[0.875rem] pl-3.5 pt-[1.25rem] leading-[0.938rem] font-poppins text-[#3C4549] font-bold">Features</p>
    <div class="mt-[0.75rem] rounded-[0.5rem] bg-white">
      <div class="flex w-full">
        <div class="w-[35rem] bg-[#FBFCFD] border-r b-[#ECEEF5] py-[0.8rem]">
          <div :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.tags}" @click="selectFeature('tags')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.tags,'!text-[#E8B34D]': linkFeaturesValidation.isValidTags}" class="text !text-[#202324]">Add Tags</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidTags" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
            <SwitchButton :status="!getSaveLink.is_tags_enabled" @click.stop="getSaveLink.is_tags_enabled = !getSaveLink.is_tags_enabled"></SwitchButton>
            </div>
          </div>
          <div :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.customizeLinkPreview}" @click="selectFeature('customizeLinkPreview')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.customizeLinkPreview, '!text-[#E8B34D]': linkFeaturesValidation.isValidMeta}" class="text !text-[#202324]">Customize Link Preview</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidMeta" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
              <SwitchButton :disabled="computeIframeErrorPrompt()" v-tooltip="computeIframeErrorPrompt() ? 'Toggle disabled in case of summary page' : ''" @click.stop="getSeoState()" :status="!getSaveLink.showSeo"></SwitchButton>
            </div>
          </div>
          <div :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.customizeFavicon}" @click="selectFeature('customizeFavicon')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.customizeFavicon, '!text-[#E8B34D]': linkFeaturesValidation.isValidFavicon}" class="text !text-[#202324]">Customize Favicon</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidFavicon" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
              <SwitchButton :disabled="computeIframeErrorPrompt()" v-tooltip="computeIframeErrorPrompt() ? 'Toggle disabled in case of summary page' : ''" @click.stop="getFaviconState()" :status="!getSaveLink.showFavicon"></SwitchButton>
            </div>
          </div>
          <div :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.passwordProtection}" @click="selectFeature('passwordProtection')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.passwordProtection, '!text-[#E8B34D]': linkFeaturesValidation.isValidPassword}" class="text !text-[#202324]">Password Protection</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidPassword" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
              <SwitchButton @click.stop="getSaveLink.password_protected = !getSaveLink.password_protected" :status="!getSaveLink.password_protected"></SwitchButton>
            </div>
            </div>
          <div :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.linkExpiry}" @click="selectFeature('linkExpiry')" class="bg-[#FBFCFD] px-[1.5rem] cursor-pointer py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.linkExpiry, '!text-[#E8B34D]': linkFeaturesValidation.isValidLinkExpiry}" class="text-[0.875rem] font-poppins">Link Expiry</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidLinkExpiry" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
            <SwitchButton @click.stop="getLinkExpiry()" :status="!getSaveLink.showLinkExpiry"></SwitchButton>
            </div>
            </div>
          <div v-tooltip="getSaveLink.is_ab_test ? 'You can not use Traffic Routing Rules if A/B Testing is activated.' : isFeaturedAllowedMessage('traffic_routing', 'Traffic Routing')" :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.trafficRouting, 'opacity-50': getSaveLink.is_ab_test || !isFeatureAllowedInPlan('traffic_routing', this)}" @click="selectFeature('trafficRouting')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.trafficRouting, '!text-[#E8B34D]': linkFeaturesValidation.isValidTrafficRouting}" class="text !text-[#202324]">Traffic Routing Rules</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidTrafficRouting" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
            <SwitchButton :disabled="getSaveLink.is_ab_test || !isFeatureAllowedInPlan('traffic_routing', this)" @click.stop="getSaveLink.is_routing_rule = !getSaveLink.is_routing_rule" :status="!getSaveLink.is_routing_rule"></SwitchButton>
            </div>
            </div>
          <div v-tooltip="getSaveLink.is_routing_rule ? 'You can not use A/B Testing if Traffic Routing Rules are activated.' : isFeaturedAllowedMessage('ab_testing', 'A/B Testing')" :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.abTesting, 'opacity-50' : getSaveLink.is_routing_rule || !isFeatureAllowedInPlan('ab_testing',this)}" @click="selectFeature('abTesting')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.abTesting, '!text-[#E8B34D]': linkFeaturesValidation.isValidABTesting}" class="text !text-[#202324]">A/B Testing</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidABTesting" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
              <SwitchButton :disabled="getSaveLink.is_routing_rule || !isFeatureAllowedInPlan('ab_testing',this)" @click.stop="getSaveLink.is_ab_test = !getSaveLink.is_ab_test" :status="!getSaveLink.is_ab_test"></SwitchButton>
            </div>
            </div>
          <div v-tooltip="widgetDisabled ? 'You can not use Deep Linking with this campaign. It is supported with shortener campaigns.' : isFeaturedAllowedMessage('deep_linking', 'Deep Linking')" :class="{'!bg-white border-l border-l-[0.25rem] border-l-[#0165E1]': toggle.deepLinking, 'opacity-50': widgetDisabled || !isFeatureAllowedInPlan('deep_linking',this)}" @click="selectFeature('deepLinking')" class="bg-[#FBFCFD] cursor-pointer px-[1.5rem] py-[0.938rem] flex justify-between items-center">
            <p :class="{'!text-[#0165E1]':  toggle.deepLinking, '!text-[#E8B34D]': linkFeaturesValidation.isValidDeepLink}" class="text !text-[#202324]">Deep Linking</p>
            <div class="flex items-center space-x-2">
              <svg v-tooltip="'Incomplete/Invalid Details'" v-if="linkFeaturesValidation.isValidDeepLink" xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 17 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.02273 2.47656C4.42032 2.47656 1.5 5.39689 1.5 8.99929C1.5 12.6017 4.42032 15.522 8.02273 15.522C11.6251 15.522 14.5455 12.6017 14.5455 8.99929C14.5455 5.39689 11.6251 2.47656 8.02273 2.47656ZM0 8.99929C0 4.56846 3.5919 0.976562 8.02273 0.976562C12.4536 0.976562 16.0455 4.56846 16.0455 8.99929C16.0455 13.4301 12.4536 17.022 8.02273 17.022C3.5919 17.022 0 13.4301 0 8.99929ZM8.02273 5.3402C8.43694 5.3402 8.77273 5.67598 8.77273 6.0902V8.99929C8.77273 9.4135 8.43694 9.74929 8.02273 9.74929C7.60851 9.74929 7.27273 9.4135 7.27273 8.99929V6.0902C7.27273 5.67598 7.60851 5.3402 8.02273 5.3402ZM8.02273 11.1584C7.60851 11.1584 7.27273 11.4942 7.27273 11.9084C7.27273 12.3226 7.60851 12.6584 8.02273 12.6584H8.03C8.44421 12.6584 8.78 12.3226 8.78 11.9084C8.78 11.4942 8.44421 11.1584 8.03 11.1584H8.02273Z" fill="#F4B740"/>
              </svg>
              <SwitchButton :disabled="widgetDisabled || !isFeatureAllowedInPlan('deep_linking',this)" @click.stop="getSaveLink.is_deep_link_enabled = !getSaveLink.is_deep_link_enabled" :status="!getSaveLink.is_deep_link_enabled"></SwitchButton>
            </div>
            </div>
        </div>
        <div class="py-6 px-10 w-full">
          <TagsComponentV2 ref="tags" :toggle="toggle.tags"></TagsComponentV2>
          <CustomizeLinkPreviewComponentV2 ref="customize_link_preview" :toggle="toggle.customizeLinkPreview"></CustomizeLinkPreviewComponentV2>
          <CustomizeFaviconComponentV2 ref="customize_favicon" :toggle="toggle.customizeFavicon"></CustomizeFaviconComponentV2>
          <PasswordProtectedComponentV2 ref="password_protected" :toggle="toggle.passwordProtection"></PasswordProtectedComponentV2>
          <LinkExpiryComponentV2 ref="link_expiry" :toggle="toggle.linkExpiry"></LinkExpiryComponentV2>
          <TrafficRoutingComponentV2 ref="traffic_routing_v2"
                                   :toggle="toggle.trafficRouting"
                                   :routing_rules="getSaveLink.routing_rules"
                                   :campaign_id="getSaveLink.call_to_action_id"
                                   :self="this"
                                   :is_ab_test="getSaveLink.is_ab_test"
                                   :traffic_routing_cta_failed="trafficRoutingCTAFailed"
                                   :traffic_routing_iframe_loader="trafficRoutingIframeLoader"
                                   :check_iframe_error="checkTrafficRoutingIframeErrorCount"
          ></TrafficRoutingComponentV2>
          <ABTestingComponentV2 ref="ab_testing"
                                :toggle="toggle.abTesting"
                                :split_urls="getSaveLink.split_urls"
                                :campaignId="getSaveLink.call_to_action_id"
                                :campaign="getSaveLink.cta"
                                :url="getSaveLink.url"
                                :self="this"
                                :is_ab_test="getSaveLink.is_ab_test"
                                :is_routing_rule="getSaveLink.is_routing_rule"
          ></ABTestingComponentV2>
          <DeepLinksComponentV2
            ref="deep_linking"
            :toggle="toggle.deepLinking"
            :is_deep_link_allowed="getSaveLink.is_deep_link_allowed"
            :url="getSaveLink.url"
            :deepLinksBrandsList="deepLinksBrandsList"
            :campaignId="getSaveLink.call_to_action_id"
            :self="this"
          ></DeepLinksComponentV2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['linkFeaturesValidation'],
  components: {
    SwitchButton: () => import('@/ui/ui-kit/v2/SwitchButton.vue'),
    CustomizeLinkPreviewComponentV2: () => import('@/views/pages/links/links-component/CustomizeLinkPreviewComponentV2.vue'),
    CustomizeFaviconComponentV2: () => import('@/views/pages/links/links-component/CustomizeFaviconComponentV2.vue'),
    PasswordProtectedComponentV2: () => import('@/views/pages/links/links-component/PasswordProtectedComponentV2.vue'),
    LinkExpiryComponentV2: () => import('@/views/pages/links/links-component/LinkExpiryComponentV2.vue'),
    TrafficRoutingComponentV2: () => import('@/views/pages/links/links-component/TrafficRoutingComponentV2.vue'),
    TagsComponentV2: () => import('@/views/pages/links/links-component/TagsComponentV2.vue'),
    ABTestingComponentV2: () => import('@/views/pages/links/links-component/ABTestingComponentV2.vue'),
    DeepLinksComponentV2: () => import('@/views/pages/links/links-component/DeepLinksComponentV2.vue')
  },
  computed: {
    ...mapGetters(['getSaveLink', 'getLinks']),
    widgetDisabled () {
      return this.isTypeCTA(this.getSaveLink.call_to_action_id, this.self)
    }
  },
  data() {
    return {
      toggle: {
        tags: true,
        customizeLinkPreview: false,
        customizeFavicon: false,
        passwordProtection: false,
        linkExpiry: false,
        trafficRouting: false,
        abTesting: false,
        deepLinking: false
      },
      trafficRoutingCTAFailed: false,
      trafficRoutingIframeLoader: false,
      checkTrafficRoutingIframeErrorCount: 0,
      deepLinksBrandsList: [],
    }
  },
  async mounted () {
    await this.getSeoState(true)
    await this.getFaviconState(true)
    await this.getLinkExpiry(true)
  },
  async created () {
    this.getSaveLink.is_tags_enabled = this.getSaveLink.tags && this.getSaveLink.tags.length > 0
    this.deepLinksBrandsList = await this.showDeepLinksBrandsList()
  },
  methods: {
    ...mapActions(['showDeepLinksBrandsList', 'fetchCustomizeLinkPreviewObj']),
    isFeaturedAllowedMessage (key, value) {
      return this.isFeatureAllowedInPlan(key, this) ? '' : `${value} is not available in your current plan. Please upgrade your plan to use this feature.`
    },
    /**
     * computing the iframe error prompt
     * @returns {false|boolean|*}
     */
    computeIframeErrorPrompt() {
      return this.isFallbackCtaEnabled() && this.getLinks.previewErrorMessage === 'notAllowed' && this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign()
    },
    selectFeature(key) {

      if(key == 'abTesting' && !this.isFeatureAllowedInPlan('ab_testing',this)) {
        return
      }

      if(key == 'trafficRouting' && !this.isFeatureAllowedInPlan('traffic_routing',this)) {
        return
      }

      if(key == 'deepLinking' && !this.isFeatureAllowedInPlan('deep_linking',this)) {
        return
      }

      this.toggle[key] = !this.toggle[key]
      Object.keys(this.toggle).forEach(otherKey => {
        if (otherKey !== key) {
          this.$set(this.toggle, otherKey, false);
        }
      });
    },
    async setCustomizeLinkPreview () {
      let linkPreviewObj = await this.fetchCustomizeLinkPreviewObj({'url': this.getSaveLink.url})
      this.metaFetchStatus = linkPreviewObj.status

      if(!this.metaFetchStatus) {
        this.resetMetadata()
        return
      }

      this.getSaveLink.custom_favicon_url = linkPreviewObj.data.favicon_url ? linkPreviewObj.data.favicon_url : ''
      this.getSaveLink.seo.title = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.title : null
      this.getSaveLink.seo.description = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.description : null
      if (linkPreviewObj.data.facebook.image && (linkPreviewObj.data.facebook.image.includes('http://') || linkPreviewObj.data.facebook.image.includes('https://'))) {
        this.getSaveLink.seo.image = linkPreviewObj.data.facebook.image
      } else {
        this.getSaveLink.seo.image = linkPreviewObj.data.images[0]
      }
    },
    async getSeoState (mounted = false) {
      const seoExists = this.getSaveLink.seo && (
        (this.getSaveLink.seo.title && this.getSaveLink.seo.title.length) ||
        (this.getSaveLink.seo.description && this.getSaveLink.seo.description.length) ||
        (this.getSaveLink.seo.image && this.getSaveLink.seo.image.length)
      )
      if (mounted && this.getSaveLink._id && !seoExists) {
        this.getSaveLink.showSeo = false
      } else if (mounted && this.getSaveLink._id && seoExists) {
        this.getSaveLink.showSeo = true
      } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.seo) {
        // setting default value for seo if the key doesn't exist
        this.getSaveLink.seo = {
          title: null,
          description: null,
          image: null
        }
        this.getSaveLink.showSeo = true
      } else if (!mounted && this.getSaveLink.seo) {
        this.getSaveLink.showSeo = !this.getSaveLink.showSeo
      }

      if (this.getSaveLink.showSeo && !this.getSaveLink._id) {
        await this.setCustomizeLinkPreview()
      }
    },
    getLinkExpiry (mounted = false) {
      const expiry = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry
      const expiry_time = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry_time
      const expiry_datetime = this.getSaveLink && this.getSaveLink.link_expire_attributes && !!this.getSaveLink.link_expire_attributes.expiry_datetime
      if (mounted && this.getSaveLink._id && (expiry && expiry_time && expiry_datetime)) {
        this.getSaveLink.showLinkExpiry = true
      } else if (mounted && this.getSaveLink._id && (expiry && expiry_time && expiry_datetime)) {
        this.getSaveLink.showLinkExpiry = true
      } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.link_expire_attributes) {
        this.getSaveLink.link_expire_attributes = {
          expiry: '',
          expiry_time: '00:00:00',
          expiry_datetime: '',
          link_expiry_logo: ''
        }
        this.getSaveLink.showLinkExpiry = true
      } else if (!mounted && this.getSaveLink.link_expire_attributes) {
        this.getSaveLink.showLinkExpiry = !this.getSaveLink.showLinkExpiry
        this.getSaveLink.link_expire_attributes = {
          expiry: '',
          expiry_time: '00:00:00',
          expiry_datetime: '',
          link_expiry_logo: ''
        }
      }
    },
    getFaviconState (mounted = false) {
      if (mounted && this.getSaveLink._id && !this.getSaveLink.custom_favicon_url) {
        this.getSaveLink.showFavicon = false
      } else if (mounted && this.getSaveLink._id && this.getSaveLink.custom_favicon_url) {
        this.getSaveLink.showFavicon = true
      } else if (!mounted && this.getSaveLink._id && !this.getSaveLink.custom_favicon_url) {
        this.getSaveLink.custom_favicon_url = ''
        this.getSaveLink.showFavicon = !this.getSaveLink.showFavicon
      } else if (!mounted && (this.getSaveLink.custom_favicon_url || this.getSaveLink.custom_favicon_url.length >= 0)) {
        this.getSaveLink.showFavicon = !this.getSaveLink.showFavicon
        this.getSaveLink.custom_favicon_url = ''
      }
    },
  },
  watch: {
    'getLinksLoaders.link_preview' (value) {

      if(this.getSaveLink.showSeo) {
        return;
      }

      // if fallback enabled and link not allowed for iframe
      if (this.computeIframeErrorPrompt() || !this.metaFetchStatus) {
        this.getSaveLink.showSeo = true
        this.getSaveLink.showFavicon = true
        this.setCustomizeLinkPreview()
        return
      }

      if (this.getSaveLink.showSeo) {
        this.setCustomizeLinkPreview()
        return
      }
    }
  }
}
</script>
